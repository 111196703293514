const customersData = [
  {
    ref: "https://www.voltabar.gr/",
    image: "2"
  },
  {
    ref: "http://www.elpidahotel.eu/",
    image: "1"
  },
  {
    ref: "https://tessa.gr/el/archiki/",
    image: "3"
  },
  {
    ref: "https://www.facebook.com/mpakalikoserres/",
    image: "4"
  }
];

export default customersData;
